import { ParsedUrlQuery } from 'querystring';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';

import { Hero7Section, Hero7Theme } from 'holded/modules/cms/domain/components/hero';
import heroPlayAnimation from 'holded/modules/cms/ui/assets/lottie/heroPlayAnimationV2.json';
import { Button2 } from 'holded/modules/cms/ui/shared/components/Button2';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';
import styles from 'holded/styles/Markdown.module.scss';

import heroStyles from './Hero7.module.scss';

const LottieLazy = dynamic(() => import('holded/modules/cms/ui/shared/components/LottiePlayer'));
const Play = dynamic(() => import('holded/modules/cms/ui/shared/icons/HeroPlay'));
const Modal = dynamic(() => import('holded/modules/cms/ui/shared/components/Modal'));
const Video = dynamic(() => import('holded/modules/cms/ui/shared/components/Video'));
const TrustpilotLine = dynamic(() => import('holded/modules/cms/ui/sections/testimonials/TrustpilotLineV3'));

type ThemeOptions = {
  background?: string;
  titleTextColor: string;
  bodyTextColor: string;
  footerTextColor: string;
};

const THEME: Record<Hero7Theme, ThemeOptions> = {
  light: {
    background: 'bg-white',
    titleTextColor: 'text-gray-dark',
    bodyTextColor: 'text-gray-600',
    footerTextColor: 'text-gray-400',
  },
  dark: {
    background: 'bg-gray-dark',
    titleTextColor: 'text-white',
    bodyTextColor: 'text-gray-400',
    footerTextColor: 'text-gray-300',
  },
  darkGradient: {
    titleTextColor: 'text-white',
    bodyTextColor: 'text-gray-400',
    footerTextColor: 'text-gray-300',
  },
  soft: {
    background: 'bg-gray-light',
    titleTextColor: 'text-gray-dark',
    bodyTextColor: 'text-gray-600',
    footerTextColor: 'text-gray-400',
  },
};

const THEME_DEFAULT = 'dark';

const DEFAULT_MOBILE_HEIGHT = '150px';
const DEFAULT_HEIGHT = '350px';

const getTheme = (theme: Hero7Theme | null | undefined): ThemeOptions =>
  THEME[theme || THEME_DEFAULT] || THEME[THEME_DEFAULT];

const getQueryParamValue = <T extends ParsedUrlQuery>(query: T, key: keyof T): string | undefined => {
  const value = query[key];
  if (!value) return undefined;

  return Array.isArray(value) ? value.join(' ') : value;
};

const Hero7 = ({
  title,
  description,
  buttons,
  footerText,
  lottie,
  image,
  imageMobile,
  theme,
  videoHref,
  trustpilot,
  trustpilotLine,
  imageHeight,
  imageMobileHeight,
  titleWidth,
  descriptionWidth,
  imageAsTitle,
  imageAsTitleHeight,
  imageAsTitleMobile,
  imageAsTitleMobileHeight,
}: Hero7Section) => {
  const [showModal, setShowModal] = useState(false);
  const router = useRouter();
  const queryParamTitle = getQueryParamValue(router.query, 'h1');

  const { background, titleTextColor, footerTextColor, bodyTextColor } = getTheme(theme);

  const imageContainerClass = lottie
    ? 'md:mt-[-60px] md:mb-[-190px] lg:mb-[-460px] md:min-h-[570px] lg:min-h-[903px]'
    : trustpilot
    ? 'md:pt-5'
    : 'md:pt-20';

  const buttonContainerClass = trustpilot ? 'pb-0' : 'pb-4';
  const footerContainerClass = trustpilot ? 'pt-4' : '';

  const hasImage = !!image?.data || !!lottie || !!imageMobile?.data;

  const handlerVideo = () => {
    setShowModal(!showModal);
  };

  const titleMaxWidth = titleWidth || '576px';
  const descriptionMaxWidth = descriptionWidth || '576px';

  const imageContainerHeightDesktop = imageHeight || DEFAULT_HEIGHT;
  const imageContainerHeightMobile = imageMobileHeight || DEFAULT_MOBILE_HEIGHT;

  const isDarkGradientTheme = theme === 'darkGradient';

  const trustPilotTheme = isDarkGradientTheme ? 'dark' : theme;

  const darkGradientClass = isDarkGradientTheme ? heroStyles.heroDarkGradient : '';

  return (
    <section className={`${background} ${darkGradientClass}`}>
      <div
        className={`flex flex-col max-w-7xl mx-auto overflow-hidden px-4 pt-10 pb-0 md:pt-20 ${
          hasImage ? 'md:pb-0' : 'md:pb-20'
        }`}
      >
        <div className="mx-auto flex flex-col items-center py-4 text-center">
          {imageAsTitle?.data && (
            <div style={{ minHeight: imageAsTitleHeight }} className="hidden md:block">
              <NextImage media={imageAsTitle.data} classImg=" h-full w-fit object-cover" lazy={false} />
            </div>
          )}
          {imageAsTitleMobile?.data && (
            <div style={{ minHeight: imageAsTitleMobileHeight }} className="block md:hidden">
              <NextImage media={imageAsTitleMobile.data} classImg="w-full object-cover" lazy={false} />
            </div>
          )}
          <div style={{ maxWidth: titleMaxWidth }}>
            <ReactMarkdown
              remarkPlugins={[gfm]}
              rehypePlugins={[rehypeRaw]}
              className={`${styles.markdown} text-subtitle1 md:text-title1 ${titleTextColor}`}
            >
              {queryParamTitle || title}
            </ReactMarkdown>
          </div>

          <div style={{ maxWidth: descriptionMaxWidth }}>
            <ReactMarkdown
              remarkPlugins={[gfm]}
              rehypePlugins={[rehypeRaw]}
              className={`${styles.markdown} text-body2 mt-4 ${bodyTextColor}`}
            >
              {description}
            </ReactMarkdown>
          </div>
        </div>
        <div className={`flex flex-col md:flex-row justify-center pt-4 ${buttonContainerClass} gap-4 z-10`}>
          {buttons?.map((button) => {
            const textColor =
              theme && ['dark', 'darkGradient'].includes(theme) && button.theme === 'transparent'
                ? 'text-white'
                : undefined;
            return (
              <Button2 key={`${button.id}${button.text}`} {...button} className={textColor} size={'xl'}>
                {button.text}
              </Button2>
            );
          })}
        </div>
        {footerText && (
          <div className={`text-center z-10 mb-10 md:mb-0 ${footerContainerClass}`}>
            <p className={`${footerTextColor} text-body3`}>{footerText}</p>
          </div>
        )}
        {trustpilot && (
          <div className={'flex justify-center items-center'}>
            {trustpilotLine && (
              <TrustpilotLine {...trustpilotLine} theme={trustPilotTheme} className={'py-4 md:py-4'} />
            )}
          </div>
        )}
        <div className={`relative flex flex-col justify-end ${imageContainerClass}`}>
          {hasImage && (
            <>
              <div
                className={`hidden md:flex ${videoHref && 'cursor-pointer'} ${!lottie && 'justify-center'} -mb-1 `}
                {...(videoHref && { onClick: handlerVideo })}
                style={{ minHeight: imageContainerHeightDesktop }}
              >
                {videoHref && (
                  <>
                    <div className={'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10'}>
                      <Play animation={heroPlayAnimation} />
                    </div>
                    <Modal open={showModal} action={handlerVideo}>
                      <Video url={videoHref} autoPlay={true} />
                    </Modal>
                  </>
                )}
                {lottie ? (
                  <LottieLazy animation={lottie} placeholder={image?.data} />
                ) : (
                  image && (
                    <NextImage media={image.data} classImg="hidden md:block h-full w-fit object-cover" lazy={false} />
                  )
                )}
              </div>
              {imageMobile && (
                <div
                  className="md:hidden flex flex-col justify-end -mb-1 overflow-hidden"
                  style={{ minHeight: imageContainerHeightMobile }}
                >
                  <NextImage media={imageMobile.data} classImg="w-full object-cover" lazy={false} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Hero7;
